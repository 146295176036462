import * as React from "react"

// styles
const pageStyles = {
  color: "#232129",
  padding: 25,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 920,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Home Page</title>
      <h1 style={headingStyles}>
        Barnes Jewellery Company
        <br/>
        <br/>
        <span style={headingAccentStyles}>— website under construction/maintenance </span>
      </h1>
      <p style={paragraphStyles}>
        Our website is currently under going a complete revamp and is currently down due to this. Our shop in Barnes is operating normal work hours.
      </p>
      <p style={paragraphStyles}>
        53 Barnes High Street, Barnes, London, SW13 9LF, United Kingdom
      </p>
      <p style={paragraphStyles}>
        paul.orford (@) barnesjewellery.com | 0208 878 9471
      </p>
      <h2>Opening Hours</h2>
  								<ul>
  									<li><p>Monday:  9:30am to 5pm</p></li>
  									<li><p>Tuesday:  9:30am to 5pm</p></li>
  									<li><p>Wednesday:  9:30am to 5pm</p></li>
  									<li><p>Thursday:  9:30am to 5pm</p></li>
  									<li><p>Friday:  9:30am to 5pm</p></li>
  									<li><p>Saturday:  9:30am to 5pm</p></li>
  									<li><p>Sunday:  Closed</p></li>
  								</ul>
      <br></br>
      <div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2485.145314915514!2d-0.25158848431471514!3d51.47384692097696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760e586225e615%3A0x270a6b72f1d1e0fb!2sBarnes%20Jewellery!5e0!3m2!1sen!2s!4v1608477853179!5m2!1sen!2s" width="100%" height="550" frameborder="0"allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </div>

    </main>
  )
}

export default IndexPage
